.sidebar {
  min-width: 300px;
  min-height: calc(100vh - 270px);
  padding: 20px 50px;

  display: flex;
  flex-direction: column;
  justify-content: start;

  .logo_container {
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    
    .logo {
      width: 160px;
    }
  }

  .navigations {
    .list_item {
      all: unset;
      padding: 20px 0;
      width: 100%;
      border-bottom: 1px solid #EAEAEA;
    }
    
    ul li:last-child div {
      border: none;
    }
    
    .tab {
      width: 100%;
      padding: 0;

      .route {
        display: flex;
        justify-content: space-between;

        .route_name {
          display: flex;
          align-items: center;
          gap: 25px;  
        }

        .selected {
          svg {
            fill: #2CB6D1
          }

          div {
            font-weight: bold !important;
          }
        }

        .expand_icon {
          button {
            all: unset;
            cursor: pointer;
            :hover {
              border-radius: 50%;
              background-color: #025A6630;
            }
          }
    
          .arrow_up {
            margin-top: 5px;
            transform: matrix(-1, 0, 0, -1, 0, 0);
          }
        }
      }

      .hide_content {
        display: none;
      }
      
      .subRoutes {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        margin-top: 15px;
        margin-left: 25px;
        
        .selected {
          font-weight: bold !important;
        }
    
        .subRoute {
          font: normal normal 300 14px/24px Open Sans;
          color: #393939;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 5px 0px;

          :hover {
            font-weight: bold;
          }
    
          .status {
            height: 10px;
            width: 10px;
            border-radius: 50%;
            margin-right: 10px;
            background: #C6C6C6 0% 0% no-repeat padding-box;
          }
    
          .complete {
            background: #6BE3A2 0% 0% no-repeat padding-box;
          }
    
          .incomplete {
            background: #FC9C9C 0% 0% no-repeat padding-box;
          }
        }
      }
    }
  }

  .signout_button {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 20px 5px 20px;
    
    .button {
      display: flex;
      gap: 5px;
      align-items: center;
      z-index: 9999;

      span {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

	& > div:nth-child(2) {
    margin-top: auto;
	}

  .version_number {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      color: blueviolet;
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.side_drawer {
  display: none;

  .drawer {
    margin-top: 80px;
    background-color: #07353E;
    opacity: 97%;
    padding: 50px 20px 100px;
    display: flex;
    flex-direction: column;
    justify-content: start;

    .navigations {
      .list_item {
        all: unset;
        padding: 20px 0;
        width: 100%;
        border-bottom: 1px solid #7271717e;
      }
      
      ul li:last-child div {
        border: none;
      }
      
      .tab {
        width: 100%;
        padding: 0;

        .route {
          display: flex;
          justify-content: space-between;
  
          .route_name {
            display: flex;
            align-items: center;
            color: #FFF;
            gap: 25px;

            svg {
              fill: white;
            }
          }
          .selected {
            div {
              font-weight: bold !important;
              color: #2CB6D1;
            }
            svg {
              fill: #2CB6D1;
            }
          }
  
          .expand_icon {
            button {
              all: unset;
              cursor: pointer;
              :hover {
                border-radius: 50%;
                background-color: #FFF;
              }
            }
      
            .arrow_up {
              margin-top: 5px;
              transform: matrix(-1, 0, 0, -1, 0, 0);
            }
          }
        }
  
        .hide_content {
          display: none;
        }
        
        .subRoutes {
          display: flex;
          flex-direction: column;
          align-items: baseline;
          margin-top: 15px;
          margin-left: 25px;
      
          .subRoute {
            font: normal normal 300 14px/24px Open Sans;
            color: #FFF;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 5px 0px;
  
            :hover {
              font-weight: bold;
            }
       
            .selected {
              font-weight: bold !important;
            }
      
            .status {
              height: 10px;
              width: 10px;
              border-radius: 50%;
              margin-right: 10px;
              background: #C6C6C6 0% 0% no-repeat padding-box;
            }
      
            .complete {
              background: #6BE3A2 0% 0% no-repeat padding-box;
            }
      
            .incomplete {
              background: #FC9C9C 0% 0% no-repeat padding-box;
            }
          }
        }
      }
    }

    .signout_button {
      margin-top: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 20px 5px 20px;
      
      .button {
        display: flex;
        gap: 5px;
        align-items: center;
        z-index: 9999;
        
        svg {
          fill: white;
        }

        span {
          color: white;
          font-size: 16px;
          font-weight: 500;
        }
      }
    }

    & > div:nth-child(2) {
      margin-top: auto;
    }

    .version_number {
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        color: white;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}

@media (max-width: 1024px) {
  .sidebar {
    width: 250px !important;
  }
}


@media screen and (max-width: 1023px) {
  .sidebar {
    display: none;
  }

  .side_drawer {
    display: block;
  }
}
