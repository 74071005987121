.information_section {
  margin-top: 40px;
  border-bottom: 1px solid #A3ACAD70;
  width: 100%;
  // min-width: 350px;

  .title {
    font: normal normal bold 14px/20px Open Sans;
    color: #A3ACAD;
    text-transform: uppercase;
  }

  .content {
    margin: 25px 0;
  }
}