.page {
  background-image: url('../../../public/images/background.jpg');
  background-size: cover;
  background-position: center center;
  max-height: 100vh;
  overflow-y: scroll;

  .page_container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 190px);
    margin: auto;
    
    .page_content {
      background-color: #FFFFFF;
      width: fit-content;
      margin: 40px auto;
      border-radius: 20px;
      overflow: hidden;
      opacity: 0.98;
    }
  }
}

.left_aligned_bg {
  background-image: url("../../../public/images/background-left.jpg") !important;
}

@media screen and (max-width: 1023px) {
  .page {
    background-image: url("../../../public/images/background-mobile.jpg") !important;
    background-size: cover;

    .page_container {
      display: block;;
      min-height: calc(100vh - 220px) !important;

      .page_content {
        margin: unset;
        width: 100%;
        height: 100%;
        overflow: scroll;
        border-radius: unset;
        min-height: calc(100vh - 220px);
        display: flex;
        justify-content: center;
      }
    }
  }
}
