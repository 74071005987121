.header {
  position: relative;

  .header_background {
    background: #07353E;
    mix-blend-mode: multiply;
    height: 100px;
  }

  .header_content {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    width: 100%;
    padding: 0 45px;
    
    .logo_container {
      .logo {
        // width: 200px; #commenting this for now as it was stretching the logo
        height: 60px;
      }
    }

    .greetings_container {
      display: flex;
      align-items: center;

      .greetings {
        color: #FFFFFF;
        margin-right: 20px;
        font-size: 16px;
        font-weight: 300;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .header_background {
    height: 80px !important;
  }

  .header_content {
    padding: 0 25px !important;
    height: 80px !important;

    .logo_container {
      .logo {
        width: 140px !important;
        height: 40px !important;
      }
    }

    .greetings_container {
      .sign_out {
        display: none !important;
      }
    }
  }
}