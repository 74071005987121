.authentication_container {
  display: flex;
  flex-direction: column;
  padding: 40px;

  .heading {
    color: #393939;
    text-align: center;
    align-self: center;
    width: 357px;
  
    .title {
      font-size: 32px;
      font-weight: bold;
    }
    
    .content {
      font-size: 20px;
      font-weight: 300;
    }
  }
  
  .form {
    margin-top: 30px;
    
    .field {
      margin-bottom: 14px;
      font: normal normal normal 15px/20px Open Sans;
    }
    
    .side_by_side {
      display: flex;
      gap: 30px;
    }
    
    .reset_password_button {
      display: flex;
      justify-content: end;
      
      a {
        padding: 0;
      }
    }
    
    .submit_button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
    }

    .TCcontainer{
      margin-left: 10px;

      .TCstatement {
        color: #2CB6D1;
        cursor: pointer;
      }
    }
  }
}

.terms_and_conditions_modal {
  max-width: 85vw !important;

  .tc_modal_body {
    overflow: auto !important;
    flex: 1 !important;
    max-height: calc(100vh - 180px) !important;
    box-sizing: border-box !important;
  }

  .tc_modal_container_content {
    margin: 15px !important;
  }
}

@media screen and (max-width: 1023px) {
  .title {
    font-size: 28px !important;
  }
  
  .content {
    font-size: 17px !important;
  }

  .terms_and_conditions_modal {
    max-width: 100vw !important;

    .tc_modal_body {
      overflow: auto !important;
      flex: 1 !important;
      max-height: calc(100vh - 180px) !important;
      box-sizing: border-box !important;
    }

    .tc_modal_container_content {
      margin: 10px !important;
    }
  }
}
