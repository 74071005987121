.dashboard_container {
  width: 85vw;
  max-width: 1300px;
  height: 100%;
  display: flex;

  .content_container {
    width: 100%;
    padding: 35px 40px;
    background-color: #EAF0F2;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}

.sm_container {
  width: 85vw;
  max-width: 1300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;

  .heading {
    color: #393939;
    text-align: center;
    align-self: center;

    .title {
      font-size: 32px;
      font-weight: bold;
    }

    .content {
      font-size: 20px;
      font-weight: 300;
    }
  }

  .content_container {
    width: 100%;
    padding: 35px 40px;
    background-color: #EAF0F2;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .rich_text_html {
    overflow: auto;
    flex: 1;
    max-height: calc(100vh - 390px);
  }
}

@media screen and (max-width: 1023px) {
  .dashboard_container {
    min-height: fit-content !important;
    height: 100% !important;
    width: 100% !important;

    .content_container {
      border-radius: unset;
      padding: 20px 25px !important;
      min-height: calc(100vh - 220px) !important;
    }
  }
}