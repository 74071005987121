.label {
  font-weight: 300;
  font-size: 13px;

  small {
    font-size: 11px !important;
  }
}

.field {
  border: 1px solid #A3ACAD !important;
  border-radius: 7px;
  background-color: #EAF0F2;
  font-weight: 300;
  font-size: 14px;
  color: #393939;
}

@media screen and (max-width: 1023px) {
  .field {
    width: 100% !important;
  }
}