.header {
  letter-spacing: 0px;
  color: #393939;

  .heading {
    .icon {
      margin-right: 10px;  
    }

    font-weight: bold;
    font-size: 28px;
  }

  .description {
    margin-top: 5px;
    font-size: 15px;
  }

  .divider {
    margin: 25px 0;
    color: #868686;
  }
}

@media screen and (max-width: 1023px) {
  .heading {
    font-size: 24px !important;
    white-space: wrap;
  }

  .description {
    font-size: 14px !important;
  }
}
