.select {
  display: flex;
  flex-direction: column;

  .label {
    font-weight: 300;
    font-size: 13px;
  }
  .field {
    height: 40px;
    background: #FFFFFF;
    border-color: #393939 !important;
    border-radius: 7px !important;
    
    .select {
      color: #393939 !important;
      font-weight: 300;
      font-size: 14px;
    }
  }
}

.selected {
  background-color: rgb(44, 182, 209, 0.2) !important;
}

.checked_checkbox {
  color: #2CB6D1 !important;
}

.feedback {
  width: 100% !important;
  margin-top: 0.25rem !important;
  font-size: 0.875em !important;
  color: #dc3545 !important;
}
  
@media screen and (max-width: 1023px) {
  .field {
    width: 100% !important;
  }
}
