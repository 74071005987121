.tabs {
  gap: 40px !important;
}

.scroller {
  border-bottom: 3px solid #DEE6E6 !important;
}

.tab {
  color: #393939 !important;
  text-transform: none !important;
  padding: 0 !important;
}

.active_tab {
  color: #393939 !important;
  padding: 0 !important;
  font-weight: bold !important;
  text-transform: none !important;
}

.indicator {
  background: #2CB6D1 !important;
  height: 3px !important;
}

@media screen and (max-width: 1024px) {
  .tabs {
    gap: 20px !important;
    
    button {
      font-size: 12px;
    }
  }
}
