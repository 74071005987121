.footer {
  width: 100%;
  height: 90px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: #01282F;
  padding: 0 120px;

  .links {
    display: flex;
    gap: 0;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    font-weight: 300;
    font-size: 14px;
    
    .copyright {
      margin-right: 5px;
      font-weight: 300;
    }
    
    a {
      padding: 5px;
      font-size: 14px;
      color: #FFFFFF;
      font-weight: 600;
    }
  }

  .haysatck_button {
    .haystack_logo {
      display: none;
    }
  
    a {
      font-size: 14px;
      font-weight: 300;
      display: flex;
      align-items: center;
      color: #FFFFFF;
      gap: 7px;
    }
  }
}

@media screen and (max-width: 1023px) {
  .footer {
    width: 100%;
    height: 100% !important;
    padding: 25px !important;
    flex-direction: column-reverse !important;

    .links {
      flex-direction: column-reverse !important;
      margin-top: 20px;

      a {
        font-size: 13px;
      }

      .copyright {
        font-size: 13px;
        opacity: 0.8;
      }

      .separator {
        color: #01282F;
      }
    }

    .haysatck_button {
      .haystack_logo {
        display: unset;
        
        img {
          width: 160px !important;
          height: 50px !important;
        }
      }
      a {
        font-size: 12px;
        padding: 10px 0px;
      }
    }
  }
}
