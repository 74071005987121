.section {
  margin-top: 40px;
  width: fit-content;

  .resume_section {
    width: 100%;
    max-width: 400px;
    background-color: #DFE6E6;
    border-radius: 10px;
    margin-bottom: 15px;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      font-size: 16px;
      font-weight: 400;
      color: #025A66;
      padding: 0 20px;
      gap: 20px;
  
      .icons {
        display: flex;
        gap: 10px;
  
        button {
          all: unset;
          cursor: pointer;
          :hover {
            border-radius: 50%;
            background-color: #025A6630;
          }
        }
      }
    }

    @media screen and (max-width: 1023px) {
      .title {
        font-size: 15px;
      }
    }
  }

  .form {
    .input_field {
      margin-top: 10px;
      
      input {
        background-color: #FFFFFF;
      }

      textarea {
        background-color: #FFFFFF;
        width: 100% !important;
      }
      
      textarea::placeholder {
        color: #A3ACAD;
        font-size: 14px;
        font-weight: 300;
      }
    }

    .input_field_about {
      textarea {
        margin-top: 15px;
      }
    }

    .label {
      font-weight: 300;
      font-size: 13px;
      margin-bottom: 15px;
    }

    .required_characters {
      text-align: end;
      font-size: 14px;
      color: #616262;
    }

    .characters_exceeded {
      color: #FC9C9C;
    }

    .checkbox_section {
      margin-bottom: 30px;
      .label {
        font-size: 14px;
        font-weight: 300;
      }

      .checkboxes {
        margin-top: 15px;
        display: flex;
        gap: 30px;

        input[type='radio'] {
          accent-color: #256E7C;
        }
      }
    }

    .add_button {
      margin-top: 30px;

      button {
        background-color: unset !important;
        color: #256E7C;
        border: 2px solid #256E7C;

        display: flex;
        align-items: center;

        :hover {
          color: #256E7C !important;
        }
      }      
    }

    .side_by_side {
      display: flex;
      gap: 30px;
    }

    .checkbox_field {
      margin-top: 30px;

      span {
        margin-left: 10px;
      }

      input {
        accent-color: #256E7C;
      }
    }

    .submit_button {
      margin-top: 30px;
    }
  }
}


@media screen and (max-width: 1023px) {

  .section {
    width: 100%;
    max-width: 500px;
  }
  
  .side_by_side {
    display: block !important;
  }
}