.account_button {
  float: right;
}

.timeline_container {
  margin-top: 20px;

  .timeline_dot {
    color: white;
    background-color: #2CB6D1;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }

  .timeline_item::before {
    display: none;
  }

  .timeline_content {
    padding: 0px 16px 16px 16px;
    font-family: 'Open Sans';
    color: #393939;

    .timeline_content_container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .container_heading {
        display: flex;
        align-items: center;

        span {
          font-size: 19px;
          font-weight: bold;
          margin-left: 10px;
          color: #393939;
        }
      }

      .grey_text {
        font-weight: 300;
        font-size: 16px;
        margin: 5px 5px 10px;
        margin-top: 5px;
        margin-bottom: 10px;

        a {
          color: #2CB6D1 !important;
        }
      }

      .profile_step_container {
        display: flex;
        flex-direction: column;

        .step_container {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          gap: 15px;

          a {
            width: 90%;
            max-width: 550px;

            .step {
              display: flex;
              align-items: center;
              background: #FFFFFF 0% 0% no-repeat padding-box;
              box-shadow: 0px 3px 6px #00000029;
              border-radius: 10px;
              height: 66px;
              padding: 20px;
              justify-content: space-between;
              cursor: pointer;
              transition: 0.7s;
  
              .step_content{
                display: flex;
                align-items: center;

                svg {
                  min-width: 28px;
                }

                .step_title {
                  margin-left: 10px;
                  font-weight: 600;
                  font-size: 16px;
                  color: #393939;
                  line-height: 18px;
                }
              }

              button {
                padding: 15px;
                min-width: 100px !important;
              }
            }
          }

          .step:hover {
            transform: translate(0, -5px);
            color: #2CB6D1 !important;

            svg {
              fill: #2CB6D1 !important;
            }

            .step_title {
              color: #2CB6D1 !important;
            }

            button {
              background-color: #2CB6D1 !important;
              color: white !important;
            }
          }
        }
      }
    }
  }

}
@media screen and (max-width: 1023px) {

  .timeline_container {
    ul {
      padding: 0 !important;
    }

    .timeline_dot {
      width: 25px !important;
      height: 25px !important;
    }

    .timeline_content {
      padding: 0 10px 10px 5px;

      .timeline_content_container {
        .container_heading {
          span {
            font-size: 17px !important;
            margin-left: 5px;
          }
        }

        .grey_text {
          font-size: 14px;
        }

        .profile_step_container {
          .step_container {
            margin-bottom: 10px;
            gap: 5px;
            
            a {
              width: 100% !important;
              
              .step {
                height: 60px;
                padding: 0 10px !important;
    
                .step_content{
                  width: 100% !important;
                  max-width: 230px !important;

                  .step_title {
                    width: 100%;
                    margin-left: 5px;
                    font-weight: 600;
                    font-size: 13px;
                    color: #393939;
                  }
                }

                button {
                  min-width: 25px !important;
                  white-space: nowrap;
                  padding: 15px ;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}
