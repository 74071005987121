.expandable_section {
  width: 500px;
  background-color: #DFE6E6;
  border-radius: 10px;
  margin-bottom: 15px;
  
  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 55px;
    font-size: 16px;
    font-weight: 600;
    color: #025A66;
    padding: 0 20px;

    .heading_content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      max-width: 445px;

      button {
        all: unset;
        cursor: pointer;
        :hover {
          border-radius: 50%;
          background-color: #025A6630;
        }
      }

      .arrow_up {
        margin-top: 5px;
        transform: matrix(-1, 0, 0, -1, 0, 0);
      }

      .arrow_right {
        transform: rotate(90deg);
      }

      .svgIcon {
        width: 1.5em;
        height: 1.5em;
        margin-right: 8px;
      }
      
      .validProject {
        color: #32CD32; /* Brighter green for checkmark */
      }
      
      .invalidProject {
        color: darkred;
      }
      
      .title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 20px;
      }
    }
  }

  button {
    all: unset;
    cursor: pointer;
  }

  .content {
    padding: 0 30px 30px;
    height: fit-content;
  }

  .hide_content {
    display: none;
  }
}

@media screen and (max-width: 1023px) {
  .expandable_section {
    width: 100% !important;
    max-width: 425px !important;
  }

  .heading_content {
    max-width: 280px !important;
  }
}
