.form {
  margin-top: 30px;

  .field {
    margin-bottom: 14px;
    font-size: 14px;
    font-weight: 300;

    input {
      background-color: #FFFFFF;
    }
  }

  .side_by_side {
    display: flex;
    gap: 30px;
  }

  .submit_button {
    margin-top: 30px;
  }
}

@media screen and (max-width: 1023px) {
  .side_by_side {
    display: block !important;
  }
}